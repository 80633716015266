import { useEffect, useState } from "react";
import { roundNumber, roundToTwo } from "../../services/Utilitario";
import { CodigoImpuestoMap, CodigoImpuestoMap2 } from "../Constantes";
import { toast } from "react-toastify";

/**
 * @typedef {object} TotalesObj
 * @property {number} subTotalIva - subTotal con el valor de IVA calculado (generalmente 12%).
 * @property {number} subTotalIva5 - subTotal con el valor de IVA calculado (generalmente 5%).
 * @property {number} subTotalIva15 - subTotal con el valor de IVA calculado (generalmente 15%).
 * @property {number} subTotalIva13 - subTotal con el valor de IVA calculado (generalmente 13%).
 * @property {number} subTotalIva8 - subTotal con el valor de IVA calculado (generalmente 8%).
 *
 * @property {number} subTotalIva0 - subTotal con el valor de productos que esten bajo IVA 0.
 *
 * @property {number} subTotalNoObjetoIva - subTotal con el valor de productos que esten bajo NO OBJETO DE IMPUESTOS.
 * @property {number} subTotalExentoIva - subTotal con el valor de productos que esten EXTENTOS DE IVA.
 * @property {number} totalSinImpuesto - subTotal con el valor total sin impuesto agregado.
 * @property {number} totalDescuentos - subTotal con el valor total de todos los descuentos sumados.
 * @property {number} totalIce - subTotal con el valor total de todos los ICE sumados.
 *
 *
 *
 *
 * @property {number} totalIva - subTotal con el valor total de todos los IVA sumados.
 * @property {number} totalIva5 - subTotal con el valor total de todos los IVA sumados (generalmente 5%).
 * @property {number} totalIva15 - subTotal con el valor total de todos los IVA sumados (generalmente 15%).
 * @property {number} totalIva13 - subTotal con el valor total de todos los IVA sumados (generalmente 13%).
 * @property {number} totalIva8 - subTotal con el valor total de todos los IVA sumados (generalmente 8%).
 *
 *
 *
 *
 *
 * @property {number} totalIRBPNR -
 * @property {number} propina - subTotal de todas las propinas sumadas.
 * @property {number} importeTotal - Total de valores sumados.
 * @property {number} ValorTotal - Total de valores sumados.
 * @property {number} TotalFormaPago - Total de valores sumados de forma de pago.
 * @property {string} TipoImpuesto - Tipo de impuesto IVA en texto, para ser pasado al TotalDocumento.
 */

/**
 * @typedef {object} useTotalesReturn
 * @property {TotalesObj} - Objeto con los valores Totales calculados, y cargados a nivel reactivo por sus Detalles y FormaPagos pasados por referencia.
 */

/**
 * @param {Array} Detalles - Lista de productos y servicios para calculo de subtotal.
 * @param {Array} FormaPagos - Lista de formas de pago y sus respectivos valores para calculo de subtotal.
 * @returns {useTotalesReturn} - Objeto con los valores Totales Calculados
 */
const useTotales = (Detalles, FormaPagos = []) => {
  /** * @type {TotalesObj} */
  const TotalInicial = {
    subTotalIva: 0,
    subTotalIva0: 0,
    subTotalIva5: 0,
    subTotalIva15: 0,
    subTotalIva13: 0,
    subTotalIva8: 0,
    subTotalNoObjetoIva: 0,
    subTotalExentoIva: 0,
    totalSinImpuesto: 0,
    totalDescuentos: 0,
    totalIce: 0,
    totalIva: 0,
    totalIva5: 0,
    totalIva15: 0,
    totalIva13: 0,
    totalIva8: 0,
    totalIRBPNR: 0,
    propina: 0,
    importeTotal: 0,
    TipoImpuesto: "15%",
    TotalFormaPago: 0,
  };

  /** * @type {TotalesObj} */
  const [Totales, setTotales] = useState(TotalInicial);

  const [contador, setContador] = useState(0);
  const [contador5, setContador5] = useState(0);
  const [contador13, setContador13] = useState(0);
  const [contador8, setContador8] = useState(0);
  const [contador12, setContador12] = useState(0);
  
  const [ultimaAccion, setUltimaAccion] = useState(null);
  const [ultimaAccion5, setUltimaAccion5] = useState(null);
  const [ultimaAccion13, setUltimaAccion13] = useState(null);
  const [ultimaAccion8, setUltimaAccion8] = useState(null);
  const [ultimaAccion12, setUltimaAccion12] = useState(null);
  
  const incrementarContador = () => {
    setContador((prevContador) => {
      if (prevContador >= 0.10) return prevContador;
      const nuevoContador = prevContador + 0.01;
      setUltimaAccion("increment");
      return nuevoContador;
    });
    console.log(contador);
  };
  
  const decrementarContador = () => {
    setContador((prevContador) => {
      if (prevContador <= -0.10) return prevContador;
      const nuevoContador = prevContador - 0.01;
      setUltimaAccion("decrement");
      return nuevoContador;
    });
  };
  
  const incrementarContadorIVA5 = () => {
    setContador5((prevContador) => {
      if (prevContador >= 0.10) return prevContador;
      const nuevoContador = prevContador + 0.01;
      setUltimaAccion5("increment2");
      return nuevoContador;
    });
  };
  
  const decrementarContadorIVA5 = () => {
    setContador5((prevContador) => {
      if (prevContador <= -0.10) return prevContador;
      const nuevoContador = prevContador - 0.01;
      setUltimaAccion5("decrement2");
      return nuevoContador;
    });
  };
  
  
  const incrementarContadorIVA13 = () => {
    setContador13((prevContador) => {
      if (prevContador >= 0.10) return prevContador;
      const nuevoContador = prevContador + 0.01;
      setUltimaAccion13("increment3");
      return nuevoContador;
    });
  };
  
  const decrementarContadorIVA13 = () => {
    setContador13((prevContador) => {
      if (prevContador <= -0.10) return prevContador;
      const nuevoContador = prevContador - 0.01;
      setUltimaAccion13("decrement3");
      return nuevoContador;
    });
  };
  
  const incrementarContadorIVA8 = () => {
    setContador8((prevContador) => {
      if (prevContador >= 0.10) return prevContador;
      const nuevoContador = prevContador + 0.01;
      setUltimaAccion8("increment4");
      return nuevoContador;
    });
  };
  
  const decrementarContadorIVA8 = () => {
    setContador8((prevContador) => {
      if (prevContador <= -0.10) return prevContador;
      const nuevoContador = prevContador - 0.01;
      setUltimaAccion8("decrement4");
      return nuevoContador;
    });
  };
  



  const incrementarContadorIVA12 = () => {
    setContador12((prevContador) => {
      if (prevContador >= 0.10) return prevContador;
      const nuevoContador = prevContador + 0.01;
      setUltimaAccion12("increment5");
      return nuevoContador;
    });
  };
  
  const decrementarContadorIVA12 = () => {
    setContador12((prevContador) => {
      if (prevContador <= -0.10) return prevContador;
      const nuevoContador = prevContador - 0.01;
      setUltimaAccion12("decrement5");
      return nuevoContador;
    });
  };
  
  useEffect(() => {
    const nuevosTotales = CalcularSubTotales();
  
    setTotales((prevState) => {
      return {
        ...prevState,
        ...CalcularSubTotales(),
        totalIva15: Number(( roundToTwo(  nuevosTotales.totalIva15) + (ultimaAccion === "increment" ? contador : contador)).toFixed(2)),
        totalIva5: Number(( roundToTwo( nuevosTotales.totalIva5) + (ultimaAccion5 === "increment2" ? contador5 : contador5)).toFixed(2)),
        totalIva13: Number((roundToTwo(nuevosTotales.totalIva13) + (ultimaAccion13 === "increment3" ? contador13 : contador13)).toFixed(2)),
        totalIva8: Number((roundToTwo(nuevosTotales.totalIva8) + (ultimaAccion8 === "increment4" ? contador8 : contador8)).toFixed(2)),
        totalIva: Number((roundToTwo(nuevosTotales.totalIva) + (ultimaAccion12 === "increment5" ? contador12 : contador12)).toFixed(2)),
        importeTotal:
          nuevosTotales.importeTotal +
          (ultimaAccion === "increment" ? contador : contador) +
          (ultimaAccion === "increment2" ? contador5 : contador5) +
          (ultimaAccion === "increment3" ? contador13 : contador13) +
          (ultimaAccion === "increment4" ? contador8 : contador8) +   (ultimaAccion === "increment5" ? contador12 : contador12)
        ,
        ValorTotal:
          nuevosTotales.ValorTotal +
          (ultimaAccion === "increment" ? contador : contador) +
          (ultimaAccion === "increment2" ? contador5 : contador5) +
          (ultimaAccion === "increment3" ? contador13 : contador13) +
          (ultimaAccion === "increment4" ? contador8 : contador8) + 
          (ultimaAccion === "increment5" ? contador12 : contador12)
  
        /*         totalIva15: nuevosTotales.totalIva15 - ajuste,
              importeTotal: nuevosTotales.importeTotal - ajuste,
              ValorTotal: nuevosTotales.ValorTotal - ajuste, */
      };
    });
    console.log(Totales);
  }, [FormaPagos, Detalles, contador, contador5, contador13, contador8,contador12]);
  

  /*   useEffect(() => {
    const nuevosTotales = CalcularSubTotales();
    setTotales((prevState) => {
      return {
        ...prevState,
        ...CalcularSubTotales(),
        totalIva15: nuevosTotales.totalIva15 + contador,
        importeTotal: nuevosTotales.importeTotal + contador,
        ValorTotal: nuevosTotales.ValorTotal + contador,
      };
    });
  }, [incrementarContador]); */

  /**
   * @returns {number} - Valor Total de la suma de las formas de pago.
   */
  const GetTotalFormaPago = () => {
    let totalFormaPago = 0;
    // console.log(FormaPagos);
    FormaPagos.forEach((a) => (totalFormaPago += Number(a.valor)));
    return roundToTwo(totalFormaPago);
  };

  /**
   * @param {TotalesObj} ToCalculate - Referencia de objeto a calcular ValorTotal.
   * @returns {number} - ValorTotal calculado.
   */
  const calcularValorTotal = (ToCalculate) => {
    let formasPagoFactura = 0;
    FormaPagos.forEach((el) => (formasPagoFactura += roundToTwo(el.valor)));
    return roundToTwo(ToCalculate.importeTotal) - roundToTwo(formasPagoFactura);
  };

  /**
   * @param {TotalesObj} ToCalculate - Referencia de objeto a calcular el total de valores sin impuestos.
   * @returns {number} - Valor TotalSinImpuesto calculado.
   */
  const calcularTotalSinImpuesto = (ToCalculate) => {
    return roundToTwo(
      roundToTwo(ToCalculate.subTotalIva) +
        roundToTwo(ToCalculate.subTotalIva0) +
        roundToTwo(ToCalculate.subTotalIva5) +
        roundToTwo(ToCalculate.subTotalIva8) +
        roundToTwo(ToCalculate.subTotalIva13) +
        roundToTwo(ToCalculate.subTotalIva15) +
        roundToTwo(ToCalculate.subTotalNoObjetoIva) +
        roundToTwo(ToCalculate.subTotalExentoIva)
    );
  };

  /**
   * @param {TotalesObj} ToCalculate - Referencia de objeto a calcular el total general.
   * @returns {number} - Valor TotalSinImpuesto calculado.
   */
  const calcularImporteTotal = (ToCalculate) => {
    return roundToTwo(
      roundToTwo(ToCalculate.totalSinImpuesto) +
        roundToTwo(ToCalculate.propina) +
        roundToTwo(ToCalculate.totalIva) +
        roundToTwo(ToCalculate.totalIva5) +
        roundToTwo(ToCalculate.totalIva8) +
        roundToTwo(ToCalculate.totalIva13) +
        roundToTwo(ToCalculate.totalIva15)
    );
  };

  /**
   * @param {TotalesObj} Totales - Referencia de objeto para calcular y sumarle sus respectivos impuestos.
   * @param {Object} element - producto o servicio para ser calculado.
   */

  const CalcularValoresPorImpuestos = (Totales, element) => {
    if (!element.idImpuestoIvaNavigation) {
      toast.error("Producto sin IVA");
    } else {
      if (element.idImpuestoIvaNavigation) {
        switch (element.idImpuestoIvaNavigation.idImpuesto) {
          case 1:
            switch (CodigoImpuestoMap[element.idImpuestoIvaNavigation.codigo]) {
              case "IVA 0":
                Totales.subTotalIva0 = roundToTwo(
                  roundToTwo(Totales.subTotalIva0) +
                    roundToTwo(element.subTotal)
                );
                break;

              case "NO OBJETO IMP":
                Totales.subTotalNoObjetoIva = roundToTwo(
                  roundToTwo(Totales.subTotalNoObjetoIva) +
                    roundToTwo(element.subTotal)
                );
                break;

              case "EXENTO IVA":
                Totales.subTotalExentoIva = roundToTwo(
                  roundToTwo(Totales.subTotalExentoIva) +
                    roundToTwo(element.subTotal)
                );
                break;

              case "IVA 14":
              case "IVA 12":
                element.iva =
                  roundToTwo(element.subTotal) *
                  element.idImpuestoIvaNavigation.porcentaje;
                Totales.totalIva += element.iva;
                Totales.subTotalIva += roundToTwo(element.subTotal);
                Totales.TipoImpuesto = `${
                  element.idImpuestoIvaNavigation.porcentaje * 100
                }%`;
                break;
              case "IVA 15":
                //element.iva = roundToTwo((element.subTotal * element.idImpuestoIvaNavigation.porcentaje).toFixed(3));
                element.iva =
                roundToTwo(element.subTotal) *
                element.idImpuestoIvaNavigation.porcentaje;

                Totales.totalIva15 += element.iva;
                Totales.subTotalIva15 += roundToTwo(element.subTotal);
                Totales.TipoImpuesto = `${
                  element.idImpuestoIvaNavigation.porcentaje * 100
                }%`;
                break;
              case "IVA 13":
                element.iva =
                  element.subTotal * element.idImpuestoIvaNavigation.porcentaje;
                Totales.totalIva13 += element.iva;
                Totales.subTotalIva13 += roundToTwo(element.subTotal);
                Totales.TipoImpuesto = `${
                  element.idImpuestoIvaNavigation.porcentaje * 100
                }%`;
                break;
              case "IVA 5":
                element.iva =
                  roundToTwo(element.subTotal) *
                  element.idImpuestoIvaNavigation.porcentaje;
                Totales.totalIva5 += element.iva;
                Totales.subTotalIva5 += roundToTwo(element.subTotal);
                Totales.TipoImpuesto = `${
                  element.idImpuestoIvaNavigation.porcentaje * 100
                }%`;
                break;

              case "IVA 8":
                element.iva =
                  roundToTwo(element.subTotal) *
                  element.idImpuestoIvaNavigation.porcentaje;
                Totales.totalIva8 += element.iva;
                Totales.subTotalIva8 += roundToTwo(element.subTotal);
                Totales.TipoImpuesto = `${
                  element.idImpuestoIvaNavigation.porcentaje * 100
                }%`;
                break;

              default:
                element.iva =
                  roundToTwo(element.subTotal) *
                  element.idImpuestoIvaNavigation.porcentaje;
                Totales.totalIva += element.iva;
                Totales.subTotalIva += roundToTwo(element.subTotal);
                Totales.TipoImpuesto = `${
                  element.idImpuestoIvaNavigation.porcentaje * 100
                }%`;

                break;
            }
            break;
          default:
            break;
        }
      }
    }
  };

  /**
   *
   * @returns {TotalesObj} - Orquesta todas las funciones que permiten calcular el subTotal y retorna el objeto con los Totales en su estado final.
   */
  const CalcularSubTotales = () => {
    const TotalesVal = { ...TotalInicial };

    Detalles.forEach((producto) => {
      producto.subTotal = roundToTwo(
        producto.cantidad * (producto.precioUnitario) -
          (producto.descuento ? producto.descuento : 0)
      );
      if (producto.descuento)
        TotalesVal.totalDescuentos += parseFloat(producto.descuento);

      CalcularValoresPorImpuestos(TotalesVal, producto);

      TotalesVal.totalSinImpuesto = calcularTotalSinImpuesto(TotalesVal);
      TotalesVal.importeTotal = calcularImporteTotal(TotalesVal);
      TotalesVal.ValorTotal = calcularValorTotal(TotalesVal);
      TotalesVal.TotalFormaPago = GetTotalFormaPago();
    });

    return TotalesVal;
  };

  return {
    Totales,

    incrementarContador,
    decrementarContador,
    incrementarContadorIVA5,
    decrementarContadorIVA5,
    incrementarContadorIVA13,
    decrementarContadorIVA13,
    incrementarContadorIVA8,
    decrementarContadorIVA8,

    incrementarContadorIVA12,
    decrementarContadorIVA12,

  };
};

export default useTotales;