import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getListV2 } from "../../../services";
import {
  getFechaFin,
  getFechaFinApi,
  getFechaInicio,
  getFechaInicioApi,
} from "../../../services/Utilitario";
import useCheck from "../../EmisionElectronico/hooks/useCheck";

export const ReporteFacturaContext = createContext();

const ReporteFacturaProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);
  const [start, setStart] = useState(0);

  const initialStatePaginacion = {
    pagina: 1,
    _totalpaginas: 1,
    _totalelementos: 0,
    _links: [],
    _inicioRegistro: 1,
    _totalEnPagina: 0,
    _paginaActual: 1,
  };
  const [Paginacion, setPaginacion] = useState(initialStatePaginacion);
  const [InfoTrazabilidad, setInfoTrazabilidad] = useState("");
  const [CorreosId, setCorreosId] = useState("");
  const [EditCorreos, setEditCorreos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [cargandoData, setCargandoData] = React.useState(false);
  const [cargandoData2, setCargandoData2] = React.useState(false);
  const [validation, setvalidation] = React.useState(false);
  const [AnularElement, setAnularElement] = useState(null);
  const [AutorizarElement, setAutorizarElement] = useState(null);
  const [isCompletedFiles, setIsCompletedFiles] = useState(3);
  const [IconOrder, setIconOrder] = useState(3);
  const [TipoBusqueda, setTipoBusqueda] = useState("SIMPLE");
  const [groupedData, setGroupedData] = useState({});
  const [secuencialesExistentes, setSecuencialesExistentes] = useState([]);
  const [secuencialesProblemas, setSecuencialesProblemas] = useState([]);

  const CheckedProp = useCheck(documentos);

  const formik = useFormik({
    initialValues: {
      establecimiento: "",
      puntoEmision: "",
      secuencialHasta: "",
      secuencialDesde: "",
      estado: "",
      cliente: "",
      tipoFecha: "1",
      fechaFin: getFechaFin(),
      fechaInicio: getFechaInicio(),
      TipoDocumento: "",
      Cantidad: 50,
    },
    validationSchema: Yup.object({
      receptor: Yup.string().max(300, "Tamaño del campo 300"),
      estado: Yup.string().max(300, "Tamaño del campo 300"),
      tipoFecha: Yup.string().required("El Tipo de fecha es obligatorio"),
    }),
    onSubmit: async (obj) => {
      try {
    

        if(validation){
          setCargandoData2(true)
        }
        else{
          setCargandoData(true)
        }
       

     
        if (TipoBusqueda === "SIMPLE") {
          await BusquedaSimple(
            obj.fechaInicio,
            obj.fechaFin,
            obj.cliente.trim(),
            obj.Cantidad,
            obj.estado
          );
        } else {
          await BusquedaAvanzada(
            obj.TipoDocumento,
            obj.Cantidad,
            obj.tipoFecha,
            obj.fechaInicio,
            obj.fechaFin,
            obj.establecimiento,
            obj.puntoEmision,
            obj.estado,
            obj.cliente.trim(),
            obj.secuencialDesde,
            obj.secuencialHasta
          );
        }
      } catch (error) {
        //  console.log(error);
      } finally {
        if(validation){
          setCargandoData2(false)
        }
        else{
          setCargandoData(false)
        }
        setvalidation(false);
       
      }
    },
  });

  useEffect(() => {
    BusquedaSimple();
  }, []);

  const BusquedaAvanzada = async (
    TipoDocumento = "",
    Cantidad = 50,
    tipoFecha = 1,
    fechaInicio = getFechaInicio(),
    fechaFin = getFechaFin(),
    establecimiento = "",
    puntoEmision = "",
    estado = "",
    cliente = "",
    secuencialDesde = "",
    secuencialHasta = ""
  ) => {
    try {
      const res = await getListV2(
        empresa,
        "BuscarDocumentoElectronicoAvanzada",
        `?Establecimiento=${establecimiento}
                &start=${start}
                &PuntoEmision=${puntoEmision}
                &fechaInicio=${getFechaInicioApi(fechaInicio)}
                &fechaFin=${getFechaFinApi(fechaFin)}
                &secuencialDesde=${secuencialDesde}
                &secuencialHasta=${secuencialHasta}
                &Estado=${estado}
                &TipoFecha=${tipoFecha}
                &Receptor=${cliente}
                &TipoDocumento=${TipoDocumento}
                &Pagina=${Paginacion.pagina}
                &Cantidad=${Cantidad}`
      );
      // console.log("asdad-----------")
      //console.log(res);

      if (!(res === undefined)) {
        setPaginacion({
          ...Paginacion,
          _totalpaginas: res._totalpaginas,
          _totalelementos: res._totalelementos,
          _links: res._links,
          _inicioRegistro: res._inicioRegistro,
          _totalEnPagina: res._totalEnPagina,
          _paginaActual: res._paginaActual,
        });
        var element = res._embedded.map((a) => {
          return { ...a, isChecked: false };
        });
        setDocumentos(element);
        setIconOrder(3);
      }
      else{
        setPaginacion(initialStatePaginacion);
      setDocumentos([]);
      setCargandoData(false);
      }
    } catch (error) {
      setPaginacion(initialStatePaginacion);
      setDocumentos([]);
      setCargandoData(false);
      /*  console.log("------------------")
            console.log(error)
            console.log("------------------") */
    }
  };

  const BusquedaSimple = async (
    fechaInicio = getFechaInicio(),
    fechaFin = getFechaFin(),
    cliente = "",
    Cantidad = 50,
    estado = ""
  ) => {
    try {
      const res = await getListV2(
        empresa,
        "BuscarDocumentoElectronicoSimple",
        `?fechaInicio=${getFechaInicioApi(
          fechaInicio
        )}&fechaFin=${getFechaFinApi(fechaFin)}
                &Receptor=${cliente}
                &TipoFecha=${1}
                &Pagina=${Paginacion.pagina}
                &Cantidad=${Cantidad}
                &Estado=${estado}`
      );

      if (!(res === undefined)) {
        setPaginacion({
          ...Paginacion,
          _totalpaginas: res._totalpaginas,
          _totalelementos: res._totalelementos,
          _links: res._links,
          _inicioRegistro: res._inicioRegistro,
          _totalEnPagina: res._totalEnPagina,
          _paginaActual: res._paginaActual,
        });
        var element = res._embedded.map((a) => {
          return { ...a, isChecked: false };
        });
        setDocumentos(element);
        setIconOrder(3);
      }
      else{
        setPaginacion(initialStatePaginacion);
      setDocumentos([]);
      setCargandoData(false);
      }
    } catch (error) {
      setPaginacion(initialStatePaginacion);
      setDocumentos([]);
      setCargandoData(false);
    
    }
  };

  return (
    <ReporteFacturaContext.Provider
      value={{
        start,
        setCargandoData2,
        cargandoData2,
        validation,
        setvalidation,
        setStart,
        documentos,
        cargandoData,
        formik,
        Paginacion,
        setPaginacion,
        initialStatePaginacion,
        InfoTrazabilidad,
        setInfoTrazabilidad,
        EditCorreos,
        setEditCorreos,
        CorreosId,
        setCorreosId,
        AnularElement,
        setAnularElement,
        AutorizarElement,
        setAutorizarElement,
        setDocumentos,
        CheckedProp,
        isCompletedFiles,
        setIsCompletedFiles,
        IconOrder,
        setIconOrder,
        TipoBusqueda,
        setTipoBusqueda,
        groupedData,setGroupedData,
        secuencialesExistentes, setSecuencialesExistentes,
        secuencialesProblemas,setSecuencialesProblemas
      }}
    >
      {props.children}
    </ReporteFacturaContext.Provider>
  );
};

export default ReporteFacturaProvider;