import React from "react";
import SubirFacturas from "./SubirFacturas";
import ReporteFacturaProvider from "../ReporteFacturaProvider";

const SubirFacturasGeneral = () => {
  return (
    <div >
        <ReporteFacturaProvider>
          <SubirFacturas/>
        </ReporteFacturaProvider>
      </div>
  );
};

export default SubirFacturasGeneral;
